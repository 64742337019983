/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "influxapi",
            "endpoint": "https://79t88ulnjl.execute-api.ap-southeast-2.amazonaws.com/uat",
            "region": "ap-southeast-2"
        },
        {
            "name": "integration",
            "endpoint": "https://e0towoewm1.execute-api.ap-southeast-2.amazonaws.com/uat",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhook",
            "endpoint": "https://udxrx85j71.execute-api.ap-southeast-2.amazonaws.com/uat",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:018b4e64-e332-477e-b247-dfa2eb737289",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_2ayVvoBz8",
    "aws_user_pools_web_client_id": "274kvcaj5634jpma21njltjev",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "commissioningCompletedMessage-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "controllableLoadConsumption-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discoreco-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discorecoTasks-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "gmexport-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "group-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "groupusers-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrol-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrolhistory-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterDataExportHistory-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterEvents-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterStatusRefreshHistory-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterstatus-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "onsitemetercomm-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "org-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "policy-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "provisioningCompletedMessage-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "service-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tasks-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "touLatestReportMessage-uat",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "user-uat",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
